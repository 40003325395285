import {z} from 'zod'

import IndexPage, {IndexPagePropsSchema} from '../components/landing/IndexPage'
import {getIndexStaticProps} from '../components/landing/utils'
import DefaultLayout from '../components/layouts/DefaultLayout'

export default function InterventionalMindsetPage({
  field,
  indexPage,
  resources,
  featuredAuthors,
  featuredJobs,
}: z.infer<typeof IndexPagePropsSchema>) {
  const headlines = {
    clinical: 'Glaucoma',
    clinicalTopicList: 'Glaucoma Topics',
    careers: 'Refractive Surgery',
    careersTopicList: 'Refractive Surgery Topics',
    sponsored: 'Ocular Surface',
  }

  return (
    <IndexPage
      field={field}
      indexPage={indexPage}
      resources={resources}
      featuredAuthors={featuredAuthors}
      featuredJobs={featuredJobs}
      headlines={headlines}
      displayFeaturedWriters={false}
      displayCompanies={false}
      displayJobsCTA={false}
      metaImg="/logos/im-meta-img.jpg"
    />
  )
}

// @ts-expect-error getLayout will be getting refactored with the release of Next 13.
InterventionalMindsetPage.getLayout = (page, pageProps) => (
  <DefaultLayout
    customNavLogo={pageProps.indexPage.customNavLogo}
    field={pageProps.field}
  >
    {page}
  </DefaultLayout>
)

export const getStaticProps = () =>
  getIndexStaticProps('Interventional Mindset')
